.range-data-mensagem {
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    color: red;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 5px;
    padding-right: 9px;
}