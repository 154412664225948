.headerColumn {
    text-align: center !important;
    font-weight: normal;
    font-size: 12px;
    padding-left: 24px;
    padding-right: 24px;
    height: 56px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: rgb(158, 158, 158);
    position: relative;
    background-color: inherit;
}

.tableRow__infos {
  text-align: center !important;
}

.no-records-message {
  width: 100%;
  text-align-last: center;
  padding-top: 20px;
  font-size: 15px;
  font-weight: var(--font-weight-regular);
}
