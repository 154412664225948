.card-shop-detail {
    width: 454px;
    margin-top: 5px;
    margin-left: 30px;
    position: relative;
}

.card-shop-detail:nth-child(1) {
    /*height: calc(100% - 5px);*/
}

.trunc-text {
    white-space: nowrap;
    width: 29em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-shop-label-detail {
    display: flex;
    flex-wrap: nowrap;
    height: 20px;
    align-items: center;
    margin-top: 20px;
}

.with-bottom {
    margin-bottom: 26px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.card-shop-font-detail {
    font-size: 11px;
    font-weight: bold;
    line-height: 1.82;
    color: #9aa4ad;
    text-transform: uppercase;
}