/* global.css */
@import "@celebration/design-tokens/dist/css/globals.css";

:root {
  --color-action-default: #2c589b;
  --color-action-hover: #abc3e7;
  --color-brand-100: #98b5e2;
  --color-brand-200: #6e92c9;
  --color-brand-300: #2c589b;
  --color-brand-400: #153566;
  --color-brand-500: #10284c;
  --color-brand-600: #0f223e;
  --color-brand-gradient: linear-gradient(90deg, #2c589b 0%, #153566 100%);
  --color-contrast-brand: #0e0e0e;
  --color-contrast-white: #ffffff;
  --color-contrast-black: #0e0e0e;
  --color-contrast-focused: #490c97;
  --color-feedback-danger-100: #ffeaea;
  --color-feedback-danger-200: #f3c0be;
  --color-feedback-danger-300: #c92323;
  --color-feedback-danger-400: #ae1e1e;
  --color-feedback-danger-500: #8e1818;
  --color-feedback-danger-600: #7a130a;
  --color-feedback-positive-100: #e1fce9;
  --color-feedback-positive-200: #d0efbd;
  --color-feedback-positive-300: #00b261;
  --color-feedback-positive-400: #08663b;
  --color-feedback-positive-500: #053821;
  --color-feedback-positive-600: #273f32;
  --color-feedback-warning-100: #fff0de;
  --color-feedback-warning-200: #ffd0ad;
  --color-feedback-warning-300: #ff9040;
  --color-feedback-warning-400: #db5c00;
  --color-feedback-warning-500: #943e00;
  --color-feedback-warning-600: #853e00;
  --color-neutral-100: #ffffff;
  --color-neutral-200: #d9d9d9;
  --color-neutral-300: #bfbfbf;
  --color-neutral-400: #666666;
  --color-neutral-500: #292929;
  --color-neutral-600: #0e0e0e;
  --color-neutral-background: #f2f2f2;
  --color-opacity-default: rgba(255, 255, 255, 0.32);
  --color-opacity-hover: rgba(171, 195, 231, 0.32);
  --color-opacity-disabled: rgba(191, 191, 191, 0.32);
  --color-opacity-error: rgba(255, 234, 234, 0.32);
  --font-family-base: 'IBM Plex Sans', sans-serif;
  --font-weight-semibold: 600;
  --font-weight-medium: 500;
  --font-weight-regular: 400;
}

 :root[data-theme='l3'] {
  --color-action-default: #a755f7;
  --color-action-hover: #d8b4fe;
  --color-brand-100: #d8b4fe;
  --color-brand-200: #c085fd;
  --color-brand-300: #a755f7;
  --color-brand-400: #7e22cf;
  --color-brand-500: #6b22a8;
  --color-brand-600: #571c86;
  --color-brand-gradient: linear-gradient(90deg, #a755f7 0%, #7e22cf 100%);
  --color-contrast-brand: #000000;
  --color-contrast-white: #ffffff;
  --color-contrast-black: #0e0e0e;
  --color-contrast-focused: #490c97;
  --color-feedback-danger-100: #ffeaea;
  --color-feedback-danger-200: #f3c0be;
  --color-feedback-danger-300: #c92323;
  --color-feedback-danger-400: #ae1e1e;
  --color-feedback-danger-500: #8e1818;
  --color-feedback-danger-600: #7a130a;
  --color-feedback-positive-100: #e1fce9;
  --color-feedback-positive-200: #d0efbd;
  --color-feedback-positive-300: #00b261;
  --color-feedback-positive-400: #08663b;
  --color-feedback-positive-500: #053821;
  --color-feedback-positive-600: #273f32;
  --color-feedback-warning-100: #fff0de;
  --color-feedback-warning-200: #ffd0ad;
  --color-feedback-warning-300: #ff9040;
  --color-feedback-warning-400: #db5c00;
  --color-feedback-warning-500: #943e00;
  --color-feedback-warning-600: #853e00;
  --color-neutral-100: #ffffff;
  --color-neutral-200: #d9d9d9;
  --color-neutral-300: #bfbfbf;
  --color-neutral-400: #666666;
  --color-neutral-500: #292929;
  --color-neutral-600: #0e0e0e;
  --color-neutral-background: #f2f2f2;
  --color-opacity-default: rgba(255,255,255, 0.32);
  --color-opacity-hover: rgba(216,180,254, 0.32);
  --color-opacity-disabled: rgba(191,191,191, 0.32);
  --color-opacity-error: rgba(255,234,234, 0.32);
  --font-family-base: 'IBM Plex Sans', sans-serif;
  --font-weight-semibold: 600;
  --font-weight-medium: 500;
  --font-weight-regular: 400;
}

* {
  font-family: var(--font-family-base);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*Será removido quando migrar para o N3 do celebration, classe adicionada para não replicar código css por inumeros
styleds*/
.input {
  box-shadow: inset -1px 0 0 0 var(--color-neutral-300), inset 0 -1px 0 0 var(--color-neutral-300), inset 1px 0 0 0 var(--color-neutral-300), inset 0 2px 0 0 var(--color-action-hover) !important;

  &:disabled {
    background-color: var(--color-neutral-200) !important;
    box-shadow: inset 0 2px 0 0 rgb(149 163 183 / 30%) !important;
  }

  &:focus {
    box-shadow: inset -1px 0 0 0 var(--color-neutral-300), inset 0 -1px 0 0 var(--color-neutral-300), inset 1px 0 0 0 var(--color-neutral-300), inset 0 2px 0 0 var(--color-action-default) !important;
  }
}
