.box {
    display: flex;
    height: calc(100% - 72px)
}

.container-master-detail {
    height: calc(100% - 45px)
}

.master-body-container {
    height: calc(100vh - 135px);
    margin: 10px 20px 0px 20px;
    background-color: var(--color-neutral-background) !important;
}
