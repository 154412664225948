.labeled-family-container {
  height: calc(100vh - 310px);
  position: relative;
  background-color: white ;
}

.labeled-family-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100px;
  align-items: center;
  background-color: var(--color-neutral-background) ;
}

.button-filter {
  margin-left: 10px;
}
