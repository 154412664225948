.table-content {
  margin: 16px;
  font-size: 18px;
}

.last-update {
  margin-left: auto;
  color: var(--color-brand-600);
  font-size: 13px;
}

.last-update span {
  font-weight: 600;
}

.icone {
  width: 15px;
  height: 15px;
}

.coluna-riscos-passivos {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 12px;
}

.icone-coluna  {
  display: flex;
  justify-content: flex-start;
  margin-left: 8px;
}

.valor-coluna  {
  display: flex;
  width: 70%;
  justify-content: center;
}

.main-span {
  font-size: 11px;
  cursor: pointer;
  text-align: center;
}

.colunas-button-dual-list {
  position: absolute;
  z-index: 100;
  left: 16px;
  width: 150px !important;
  margin-top: 10px;
  font-size: 12px;
}

img.icon {
  width: 11px !important;
}

.dual-list-button {
  margin-top: 10px;
  float: right;
}

.filterContainerCapitalEmpregado {
  margin-left: auto;
  margin-right: 20px;
  position: absolute;
  right: -16px;
}

.button-filter {
  width: 150px;
}

span {
  cursor: pointer;
}

.align-center {
  text-align: center;
  font-weight: var(--font-weight-medium);
}

.no-records {
  position: relative;
  left: 45%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: var(--color-brand-300); 
}