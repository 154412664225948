.dialog-danger-button-container {
  margin-top: 10px;
  width: 85%;
}

.button_timeline_cancel_reject {
  color: #de4246 !important;
  border: solid 1px #de4246 !important;
  margin-right: 5px;
}
