.button {
    margin: 2px;
}

.errorContainer {
    font-size: 14px;
    color: red;
}

.errorContainer p {
    margin: 1px;
}
.select-origem .radio-items-list .radio-item input[type="radio"] + label  {
    margin-right: 30px;   
  }