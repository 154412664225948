.event-action {
    width: 100%;
    align-items: right;
    margin-top: 10px;
    margin-left: 40px;
    position: relative;
    display: inline-flex;
}

.event-action .button {
    width: 200px;
}