.grp-btn-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: -10px;
}

.supplier-group-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.select-manual-sugestion {
  margin-bottom: 8px;
}

.footer-dialog-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: right;
}

.button{
  margin: 2px;
  width: 170px;
}

.option-item { 
  white-space: nowrap;
}

.container-style{
  margin-top: 5px;
}

.toggle-accept-load-comp{
  margin: 10px 0px;
}
