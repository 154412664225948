.coordination-container {
    height: calc(100% - 100px);
    position: relative;
}

.coordination-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100px;
  align-items: center;
  background-color: var(--color-neutral-background);
}
