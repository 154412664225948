.container-detail {
    width: 100%;
    height: calc(100% - 45px);
    position: relative;
}

.order-detail {
    height: calc(100% - 80px);
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.cards-order-detail {
    max-height: calc(100% - 1px);
    overflow-y: auto;
    overflow-x: hidden;
}

.heigth-card {
    height: 750px;
}

.cards-detail-container {
    display: inline-block;
    position: relative;
    height: calc(100% - 20px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.card-detail-container {
    width: 454px;
    height: 185px;
    margin-top: 30px;
    margin-left: 30px;
    position: relative;
}

.card-detail-container:nth-child(1) {
    height: 418px;
}

.notes-detail{
    height: 185px;
    margin-top: 30px;
    margin-left: 30px;
    position: relative;
}

.notes-detail-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
