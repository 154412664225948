.container-dialog-danger {
  max-height: 600px;
  display: flex;
  flex-direction: column;
}

.dialog-danger-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}

.dialog-danger-button {
  width: 50%;
}

.select-dropdown {
  width: 100%;
}

.select-dropdown label {
  font-size: 14px;
}

.button__danger-dialog {
  margin: 2px;
}

.warning-message-wrapper {
  background: var(--color-feedback-warning-100);
  border: 1px solid #fee194;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
}

.title-warning-message {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  color: #e3a809;
  margin: 5px;
}

.description-warning-message {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: var(--color-contrast-brand);
  margin: 5px;
}
