
.card-shop-mester {
    width: 330px;
    margin-top: 15px;
    margin-left: 15px;
    position: relative;
}
.button-card {
    position: absolute;
    right: 16px;
    bottom: 16px;
    cursor: pointer;
}

.btn-review-load-composition{
    width: 116px;
    height: 30px;
    font-size: 12px;
}

.btn-completar-tarefa {
    border: 0 !important;
}

.btn-check-cancellation{
  width: 112px;
  height: 35px;
}

.btns-complete-review{
  display: flex;
  align-items: center;
}
