.unities-list-container {
  height: calc(100vh - 310px);
    position: relative;
    background-color: white ;
}

.unities-header {
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  height: 100px;
  align-items: center;
  background-color: var(--color-neutral-background) ;
}

.business-unit-button-new {
  margin: 0px 2px;
}
