.accordion__input {
  padding: 8px;
  border-radius: 0px;
  background-color: var(--color-contrast-white);
  border: none;
  border-radius: 2px;
  box-shadow: inset -1px 0 0 0 var(--color-neutral-300), inset 0 -1px 0 0 var(--color-neutral-300), inset 1px 0 0 0 var(--color-neutral-300), inset 0 2px 0 0 var(--color-action-hover);
}
.accordion__input:focus-visible {
  outline: none;
}

.accordion__input--disabled {
  background-color: var(--color-neutral-200);
  box-shadow: inset 0 2px 0 0 rgb(149 163 183 / 30%)
}
