.user-container {
    height: calc(100% - 100px);
    position: relative;
}

.user-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100px;
  align-items: center;
  background-color: var(--color-neutral-background);
}

.user-button {
  margin: 0px 2px;
}

.user-filter-button {
  width: 200px;
  margin-left: 2px;
}

.button-filter {
  margin-left: 10px;
}

.button-filter img {
  padding: 0px !important;
}