.item-timeline{
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 30px;
    padding-bottom: 10px;
    flex-wrap: wrap;
}

.container-status-timeline{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 10px;
}

.datetime-event{
    width: 100%;
}

.status-timeline-id {
    width: 100%;
    color: var(--color-brand-500);
    font-size: 14px;
}

.status-timeline-observation{
     width: 100%;
}

.status-timeline-observation span:nth-child(1){
    height: 17px;
    font-size: 16px;
    font-weight: var(--font-weight-regular);
    line-height: 1.25;
    text-align: left;
    color: var(--color-brand-500);
    width: 100%;
    white-space: pre-wrap;
}

.datetime-event span{
    height: 10px;
    line-height: 1.82;
    text-align: left;
    color: var(--color-brand-500);
    width: 100%;
}

.datetime-event span:nth-child(1){
    font-size: 11px;
    font-weight: bold;
}

.datetime-event span:nth-child(2){
    font-size: 10px;
}

.datetime-event span:nth-child(3) {
    font-size: 11px;
}

.status-timeline{
    margin-top: 5px;
}

.status-timeline span:nth-child(1){
    height: 17px;
    font-size: 16px;
    font-weight: var(--font-weight-regular);
    line-height: 1.25;
    text-align: left;
    color: var(--color-brand-500);
    width: 100%;

}

.status-timeline span:nth-child(2){
    height: 17px;
    font-size: 16px;
    line-height: 1.25;
    text-align: left;
    color: var(--color-brand-500);
    width: 100%;
    margin-top: 5px;
    text-transform: uppercase;
    font-weight: bold;
}

.header-event-timeline{
    width: 100%;
    position: relative;
    display: flex;
}

.body-event-timeline{
    width: 100%;
    position: relative;
    margin-top: 10px;
}

.button_ok_cancel{
    margin-right: 10px;
    margin-left: auto;
}