.alterar-pedido-negotiation {
  display: flex;
  width: 95%;
  margin-left: 40px;
}

.alterar-pedido-negotiation-line {
  display: inline;
  justify-content: right;
}

.alterar-pedido-event-action-offer {
  display: inline;
  align-items: left;
}

.alterar-pedido-event-action-offer .button {
  width: 190px !important;
}

.alterar-pedido-label {
  line-height: 1.8rem;
  font-size: 12px;
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
  color: var(--color-contrast-black);
}

.alterar-pedido-read-column span:first-child{
  font-size: 11px;
  font-weight: bold;
  line-height: 1.82;
  text-align: left;
  color: var(--color-neutral-500);
  text-transform: uppercase;
}

.alterar-pedido-read-column span:last-child{
  font-size: 13px;
  font-weight: var(--font-weight-regular);
  line-height: 1.15;
  color: var(--color-contrast-black);
  text-align: left;
}