/* #wrapper-invoice-orders {
  display: none !important;
} */

@media print {
  #navbar-wrapper {
    display: none !important;
  }

  .container-main{
    position: initial;
    overflow-x: initial;
  }
}
