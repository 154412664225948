.negotiation {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
}

.negotiation-line {
    display: flex;
    justify-content: right;
    width: 400px;
    margin-left: 40px;
}

.negotiation-line .components-childs{
  padding-right: 0.2rem;
  padding-left: 0.2rem;
}

.event-action-offer {
    display: flex;
    width: 100%;
    align-items: center;
}

.event-action-offer .button {
    width: 190px;
}
