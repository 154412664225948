
.stocks-container {
    display: flex;
    justify-content: center;
}

.stocks-container .stocks-left,
.stocks-container .stocks-right {
    width: 40%;
}

.stocks-container .stocks-left .card-detail .Card{
    min-height: 750px;
}

.stocks-container .stocks-right {
    padding-left: 35px;
}

/* --- Card detail --- */

.stocks-container .options-wrapper {
    margin-left: auto;
    flex-shrink: 0;
}

.stocks-container .options-wrapper .icn-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 40px;
    height: 40px;
    vertical-align: top;
    cursor: pointer;
    border-radius: 50%;
}

.stocks-container .options-wrapper .icn-btn:hover{
  background-color: var(--color-action-hover);
}

.stocks-container .options-wrapper .icn-btn-programar {
    background-color: #fcfcfc;
    box-shadow: inset 0 0 0 1px var(--color-action-hover);
}

.stocks-container .options-wrapper .icn-btn-programar.programado {
    background-color: var(--color-action-hover);
}

.stocks-container .options-wrapper .icn-btn-programar.alerta {
    background-color: #ffcfcc;
    box-shadow: inset 0 0 0 1px #ff8880;
}

.stocks-container .options-wrapper .icn-btn + .icn-btn {
    margin-left: 5px;
}



/* --- Suggestion List --- */

.stocks-container .suggestion-list,
.stocks-container .orders-list {
    margin-top: 20px;
}

.stocks-container .transfer-out-list {
    margin-top: 40px;
    margin-bottom: 20px;
}

.stocks-container .suggestion-list h3,
.stocks-container .orders-list h3 {
    font-size: 18px;
    color: var(--color-action-default);
    margin: 0;
}

/* --- Timeline side --- */
.stocks-container .stocks-right-container {
    position: relative;
    width: auto;
    height: 100%;
    box-shadow: -2px 2px 5px #d0d2d4;
    background-color: var(--color-contrast-white);
}

.stocks-container .stocks-right-container .arrow {
    position: absolute;
    display: block;
    height: 68px;
    width: 25px;
}

.stocks-container .stocks-right-container .arrow:before,
.stocks-container .stocks-right-container .arrow:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    right: 100%;
    top: 8px;
}

.stocks-container .stocks-right-container .arrow:before {
    z-index: 1;
    border-style: solid;
    border-width: 25px 25px 25px 0;
    border-color: transparent var(--color-contrast-white) transparent transparent;
}

.stocks-container .stocks-right-container .arrow:after {
    border-style: solid;
    top: 5px;
    border-width: 28px 28px 28px 0;
    border-color: transparent #e2e2e2 transparent transparent;
}

.stocks-container .stocks-right-container .chart-title-row {
    padding-top: 20px;
}

.stocks-container .stocks-right-container .chart-title-row .title-graphic{
    display: flex;
    justify-content: center;
}

.stocks-container .stocks-right-container .chart-title-row .title-graphic .last-update{
    display: block;
    width: 100%;
    text-align: end;
}

.stocks-container .stocks-right-container .row-filter {
    padding-left: 10px;
    padding-right: 10px;
}

.table-list-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.table-list-footer .button {
    margin-left: 10px;
}


/*---Modal Estouro de Grade---*/
.mensagem-modal-mensagem {
    margin-bottom: 40px;
    font-weight: bold;
    color: var(--color-feedback-danger-600);
    font-size: 14px;
    text-align: center;
}

.mensagem-modal-confirmacao {
    margin-bottom: 17px;
    font-size: 16px;
    text-align: center;
}

.botoes-modal {
    display: flex;
    justify-content: flex-end;
}

.margin-botao {
    margin-left: 10px;
}

.button-start-search {
    margin-top: 28px;
}
