.autoLoad__selectbox--wrapper {
  position: relative;
  z-index: 9999;
}

.primaryMaterial__selectbox--wrapper {
  position: absolute;
  top: -18px;
}

.primaryMaterial__selectbox .Select-control {
  width: 260px;
}

.new-delivery-time {
  width: 110px !important;
}

.new-delivery-date {
  width: 100px !important;
}

.shift__selectbox .Select-control {
  width: 60px;
}

@media screen and (max-width: 1600px) {
  .primaryMaterial__selectbox .Select-control {
    width: 200px !important;
  }
  .new-delivery-time {
    width: 60px !important;
  }
  .new-delivery-date {
    width: 60px !important;
  }
}

@media screen and (max-width: 1400px) {
  .primaryMaterial__selectbox .Select-control {
    width: 140px !important;
  }
}

@media screen and (max-width: 1800px) {
  .new-delivery-time {
    width: 90px !important;
  }
  .new-delivery-date {
    width: 90px !important;
  }
}

@media screen and (max-width: 1500px) {
  .new-delivery-time {
    width: 60px !important;
  }
  .new-delivery-date {
    width: 60px !important;
  }
}
