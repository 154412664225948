.footer-dialog-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.line-buttons {
    display: flex;
    justify-content: space-between;
    justify-content: flex-end;
    width: 450px;
    position: relative;
    top: 16px;
    margin-right: 15px !important;
}

.informacoes-prioridade{
    margin-top: 15px;
}

.button-priority-tms-form{
    margin-right: 3px;
}

div[class="row"] {
    padding-top: 5px !important;
}