.general-container {
    height: calc(100% - 100px);
    position: relative;
}

.general-header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
    background-color: var(--color-neutral-background);
}

.general-button {
    margin: 0px 2px;
}

.general-filter-button {
    width: 200px;
    margin-left: 2px;
}

.button-filter img {
    padding: 0px !important;
}

.button-img-estoque {
    padding: 5px !important;
    width: 200px !important;
    margin-right: 10px !important;
    position: relative;

}

.button-img-politica-estoqueOOR {
    padding: 5px !important;
    width: 220px !important;
    margin-right: 6px !important;
    position: relative;

}

@media(max-width: 1125px){
    .button-img-estoque {
        width: 130px !important;
    }
}

.button-img-estoque img {
    height: 20px;
}

