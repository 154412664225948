.container-tasks {
    height: calc(100% - 134px);
    position: relative;
}

.cards-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    max-height: calc(100% - 92px);
}

.card-container {
    width: 310px;
    height: 185px;
    margin-top: 30px;
    margin-left: 30px;
    position: relative;
    align-self: flex-start;
}

.filterContainer {
    margin-left: auto;
    margin-right: 20px;
    display: inline-flex;
}

.button-filter {
    width: 200px !important;
    margin-right: 10px !important;
}

.button-filter img {
    padding: 0px !important;
}

.button-actions {
    margin-left: 10px;
}

.div-filters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.div-user-name {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    color: var(--color-contrast-white);
}

.gdYnVg .formConsultNF:not(.hidden) {
    z-index: 1999 !important;
}

.btn-load-more-task {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-tipo-visualizacao {
    padding-right: 33px;
}

.card-container-task {
    padding-right: 66px;
}

.align-tipo-visualizacao {
    padding: 10px 0;
    justify-content: flex-end;
}

.btnAcept, .btn-content, .selectOptions {
    display: inline-block;
    vertical-align: top;
}

.selectOptions .btnOption {
    padding: 6px;
}

.btnAcept{
    margin-right: 5px;
}

.btnUpload{
    margin-right: 10px;
    width: 60px;
}

.div-sem-registro{
    width: 100%;
    text-align: center;
    color: var(--color-neutral-600);
}

.paginationtasks {
    justify-content: flex-end;
}

.header__counters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 16px;
}