.material-container {
    height: calc(100vh - 236px);
    position: relative;
    background-color: white ;
}

.material-header {
  width: calc(100%);
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  height: 100px;
  align-items: center;
  background-color: var(--color-neutral-background) ;
}

.button-filter {
  margin-left: 10px;
}

.button-filter img {
  padding: 0px !important;
}