.card-divisor{
    width: 90%;
    height: 1px;
    background-color: var(--color-neutral-600);
}

.divisor-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}