.transit-time-date-warn {
    margin-left: 10px;
    line-height: 1.8rem;
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    color: orangered;
}  

.label-style, .label-style-text{
    line-height: 1.8rem;
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    color: var(--color-contrast-black);
  }
  
  .label-style{
    background: white;
    margin-left: 10px;
  }
  
  .label-style-title {
    margin-left: 0px;
  }

  .suggestion-alert {
    width: 15px;
    font-size: 18px;
    font-weight: bold;
    color: red;
    margin-left: 5px;
  }
