.footer-dialog-buttons-material{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: space-between;
}

.button {
    margin: 2px;
    width: 160px;
}

.container__general-form {
  width: 98% !important;
  padding: 7px;
  margin-top: -16px;
  border: 1px solid var(--color-neutral-300) !important;
}
