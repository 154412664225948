.event-action__approval {
    width: 100%;
    align-items: right;
    margin-top: 10px;
    margin-left: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.reticencias
{
    white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.notes {
    margin-left: 20px;
}

.refuse-button {
    margin-left: 20px;
}

.event-action .button {
    width: 200px;
}

.pedido-em-over
{
    display: block;
}

.negotiation-read-column:first-child{
    margin-left: 20px;
  }