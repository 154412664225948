.card-row-shop-detail-supplier {
    width: 100%;
}

.card-label-shop-detail-supplier {
    display: flex;
    flex-wrap: nowrap;
    height: 20px;
    align-items: center;
}

.card-label-shop-detail-supplier span:first-child {
    font-size: 11px;
    font-weight: bold;
    line-height: 1.82;
    color: #9aa4ad;
    text-transform: uppercase;
}

.card-label-shop-detail-supplier span:last-child {
    font-size: 14px;
    font-weight: var(--font-weight-regular);
    line-height: 1.07;
    color: #393939;
    margin-left: 5px;
}

.header-card-shop-detail-supplier {
    position: relative;
    margin-top: 15px;
    height: 45px;
    display: flex;
}

.card-shop-detail-supplier {
    width: 454px;
    height: 280px !important;
    margin-top: 5px;
    margin-left: 30px;
    position: relative;
}

.card-shop-detail-supplier {
    width: 454px;
    height: 340px !important;
    margin-top: 5px;
    margin-left: 30px;
    position: relative;
}

.timeline-actions {
    margin-top: 10px;
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
}

.timeline-actions .button {
    width: 50px !important;
}

.card-detail-button-container {
    margin-right: 2px;
}