.negotiation-volume {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
}

.negotiation-line-volume {
    display: flex;
    justify-content: right;
    width: 400px;
    margin-left: 40px;
}

.negotiation-line-volume .components-childs{
  padding-right: 0.2rem;
}

.event-action-offer-volume {
    display: flex;
    width: 100%;
    align-items: right;
}

.event-action-offer-volume .button {
    width: 195px !important; 
    margin-left: 0 !important;
    margin-right: 10px !important;
}
