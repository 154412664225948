.line-buttons-aceite {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 10px;
}

.line-buttons-aceite button {
  width: 130px !important;
}