.h-title-search {
    display: flex;
    width: 100%;
    justify-content: center;
}

.cards-container-stock .Card {
    width: 330px;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 10px;
}

.cards-container-stock .Card:not(:nth-child(3n)) {
    margin-right: 10px;
}

.cards-container-stock {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
}

.stocks-wrapper {
    min-height: calc(100vh - 215px);
}

.btn-load-more {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filterContainer {
    margin-left: auto;
    margin-right: 20px;
    display: inline-flex;
}

.buttons-header-container {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
}

.align-tipo-visualizacao {
    padding: 5px 0;
    justify-content: flex-end;
}

.div-filters {
    width: 100%;
    display: flex;
}

.btn-content, .selectOptions {
    display: inline-block;
    vertical-align: top;
}

.btn-tipo-visualizacao {
    padding-right: 33px;
}
.paginationStock {
    justify-content: flex-end;
    padding-right: 62px;
  }