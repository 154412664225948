.alterar-pedido-fornecedor {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.alterar-pedido-fornecedor-line {
  display: flex;
  justify-content: right;
  width: 400px;
  margin-left: 50px;
}

.alterar-pedido-fornecedor-read-column span:first-child{
  font-size: 11px;
  font-weight: bold;
  line-height: 1.82;
  text-align: left;
  color: var(--color-neutral-500);
  text-transform: uppercase;
}

.alterar-pedido-fornecedor-read-column span:last-child{
  font-size: 13px;
  font-weight: var(--font-weight-regular);
  line-height: 1.15;
  color: var(--color-contrast-black);
  text-align: left;
}
