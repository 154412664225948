.negotiation-read-offer{
    width: 100%;
    display: flex;
    position: relative;
    justify-content: flex-start;
}

.negotiation-read-column:last-child{
    margin-left: 20px;
}

.negotiation-read-column:not(:first-child):not(:last-child){
  margin-left: 20px;
}

.negotiation-read-column span:first-child{
    font-size: 11px;
    font-weight: bold;
    line-height: 1.82;
    text-align: left;
    color: var(--color-neutral-500);
    text-transform: uppercase;
}

.negotiation-read-column span:last-child{
    font-size: 13px;
    font-weight: var(--font-weight-regular);
    margin-left: 5px;
    line-height: 1.15;
    color: var(--color-contrast-black);
}
.negotiation-read-column-first{
    font-size: 11px;
    font-weight: bold;
    line-height: 1.82;
    text-align: left;
    color: var(--color-neutral-500);
    text-transform: uppercase;
}

.negotiation-read-column-last{
    font-size: 13px;
    font-weight: var(--font-weight-regular);
    margin-left: 5px;
    line-height: 1.15;
    color: var(--color-contrast-black);
	text-transform: none;
}
