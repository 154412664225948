.screen-container {
    display: flex;
    justify-content: center;
    padding: 25px;
}

.screen-container .screen-left,
.screen-container .screen-right {
    position: relative;
    background-color: #fcfcfc;
    box-shadow: -2px 2px 5px #d0d2d4;
    margin-top: 5px;
    padding: 20px;
    min-height: 500px;
    width: 40%;
}

.screen-container .screen-right {
    margin-left: 35px; 
}