.event-action-volume {
    width: 100%;
    align-items: right;
    margin-top: 10px;
    position: relative;
    display: inline-flex;
}
.negotiation-line-volume {
    display: flex;
    justify-content: right;
    width: 450px;
    margin-right: 5px;
}
