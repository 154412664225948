.button {
  float: right;
  margin-top: 30px;
}

.m-r {
  margin-right: 50px !important;
}

.m-l {
  margin-left: 50px !important;
}

.icone-modal {
  width: 18px;
  height: 18px;
}