.content-expandable-table-optimization .checkbox-items-list .checkbox-item input[type="checkbox"] + label:before{
  background-color: var(--color-contrast-white) !important;
}

.etb-children {
  background-color: var(--color-contrast-white);
}

.etb-children .etb-column-optimization {
  padding-left: 0px;
}

.etb-bold {
  font-weight: bold;
}

.etb-column-optimization-label {
  text-transform: uppercase;
  display: flex;
  align-items: center;
}