.card-click-area{
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
}

.header-card{
    position: relative;
    margin-top: 15px;
    height: 45px;
}

.body-card{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    margin-top: 5px;
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
}

.detail-body{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-right: 20px;
    padding-left: 20px;
    flex-wrap: wrap;
    margin-top: 15px;
}

.title-detail{
    margin-top: 10px;
    width: 100%;
}

.title-detail span{
    font-size: 11px;
    font-weight: bold;
    line-height: 1.82;
    color: var(--color-brand-200);
}

.card-label{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.card-row-detail{
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
}

.card-label-detail{
    display: flex;
    flex-wrap: nowrap;
    height: 20px;
    align-items: center;
}

.card-label-detail span:first-child{
    font-size: 11px;
    font-weight: bold;
    line-height: 1.82;
    color: #9aa4ad;
    text-transform: uppercase;
}

.card-label-detail span:last-child{
    font-size: 14px;
    font-weight: var(--font-weight-regular);
    line-height: 1.07;
    color: #393939;
}

.card-label-status {
    height: 49px;
}


.card-label span:first-child{
    font-size: 14px;
    font-weight: var(--font-weight-semibold);
    line-height: 150%;
    color: var(--color-neutral-600);
    width: 100%;
    text-transform: uppercase;
    white-space: nowrap;
}

.card-label span:last-child{
    font-size: 14px;
    font-weight: var(--font-weight-regular);
    line-height: 1.1;
    color: #393939;
}

.card-label-format-notes{
    max-width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
