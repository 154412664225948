.grp-btn-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: -10px;
}

.unities-group-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.select-manual-sugestion {
  margin-bottom: 8px;
}

.footer-dialog-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.line-buttons {
    display: flex;
    justify-content: space-between;
    width: 200px;
    position: relative;
    top: 16px;
    right: -16px;
}

.button {
    margin: 2px;
}

.container {
    width: 97% !important;
    padding: 7px;
    margin-top: -16px;
    border: 1px solid var(--color-neutral-300) !important;
}

div[class="row"] {
    padding-top: 5px !important;
}

.footer-dialog-buttons__edit-source {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: right;
}

.buttons__edit-source {
  width: 170px;
  margin: 2px
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 8px;
}
