.container-composicao-carga {
    height: calc(100% - 45px);
    position: relative;
}

.body-composicao-carga {
    padding: 17px 20px 0 20px;
    display: flex;
    justify-content: center;
}

.btn-add-float-composicao {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.btn-new-composition {
    position: absolute;
    right: 35px;
}

.btn-new-composition .btn-new {
    width: 170px !important;
    margin-right: 5px !important;
}

.legenda-composicao-container {
    margin-top: 15px;
    position: relative;
    left: 20px;
}

.legenda-composicao {
    display: flex;
    align-items: center;
    height: 30px;
}

.legenda-composicao span {
    font-size: 13px;
    margin-left: 5px;
}

.legenda {
    width: 20px;
    height: 20px;
}

.row-composicao:hover td {
    background-color: var(--color-brand-200) !important;
}

.recebida {
    background-color: var(--color-opacity-hover);
    border: 1px solid var(--color-brand-200);
}

.enviada {
    background-color: var(--color-neutral-200);
}

.filterContainer {
    margin-left: auto;
    margin-right: 20px;
    display: inline-flex;
    justify-content: flex-end;
}

.button-filter {
    width: 170px !important;
    margin-right: 10px !important;
}

.button-filter-active {
    background-color: darkred;
}

.button-filter-active img {
    padding: 0px !important;
}

.div-filters {
    width: 100%;
    display: flex;
}

.gdYnVg .formConsultNF:not(.hidden) {
    z-index: 1999 !important;
}

.button-img-small-square{
    width: 50px;
    margin-left: 10px;
}

.buttons-header-container {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
}

.content-button > img.icon{
      width: 24px !important;
      height: 24px !important;
}