.flex-outer,
.flex-inner {
    list-style-type: none;
    padding: 0;
}

.flex-outer {
    max-width: 800px;
    margin: 0 auto;
}

.flex-outer li,
.flex-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.flex-outer>li:not(:last-child) {
    margin-bottom: 20px;
}

.container-familia-edicao {
    max-width: 1200px;
    margin: 0 auto;
}

.container-familia-edicao * {
    box-sizing: border-box;
}

.line-2-columns {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.line-buttons-suggestion {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 10px;
}

.disabled {
    border: 1px solid #999;
    color: rgba(0, 0, 0, 0.870588);
    outline: 0;
}

.disabled option {
    color: #000;
}

.select-manual-sugestion {
    margin-bottom: 8px;
}

.sugestion-line select.disabled {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.298039);
}

.body-novo-pedido-sugestao {
    max-height: calc(100vh - 284px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -21px;
    margin-left: -15px;
    padding-right: 15px;
    padding-left: 10px;
    border-bottom: 1px solid rgb(224, 224, 224);
}

.msf-label-style, .msf-label-style-text{
    line-height: 1.8rem;
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    color: var(--color-contrast-brand);
  }
  
.msf-label-style{
    background: white;
    margin-left: 10px;
}
  
.msf-label-style-title {
    margin-left: 0px;
}

.msf-icon {
      width: 10px;
      height: 10px;
      position: relative;
      top: -2px;
      left: 3px;
}
.msf-icon:hover{
    cursor: help;
}

.msf-msg-geral {
    width: 300px;
    box-sizing: border-box;
}

.msf-msg-info {
    font-size: 15px;
    color: #2c3848 !important;
    background-color: var(--color-neutral-200) !important;
}

.msf-msg-info.place-right:after {
    border-right-color: var(--color-neutral-200) !important;
    border-right-style: solid !important;
    border-right-width: 6px !important;
}

.msf-msg-sucesso {
    font-size: 15px;
    color: var(--color-neutral-200) !important;
    background-color: var(--color-action-default) !important;
}
.msf-msg-sucesso.place-right:after {
    border-right-color: var(--color-action-default) !important;
    border-right-style: solid !important;
    border-right-width: 6px !important;
}