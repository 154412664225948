.coluna-risco {
  width: 100%;
  height: 100%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.botao-filtro {
  position: absolute;
  right: 10;
  width: 55px;
}

.container-filtro-itens-criticos {
  margin-left: auto;
  margin-right: 20px;
  position: absolute;
  right: -16px;
}