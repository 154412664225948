.transit-time-date-warn {
  margin-left: 10px;
  line-height: 1.8rem;
  font-size: 12px;
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
  color: orangered;
}  

.label-style, .label-style-text{
  line-height: 1.8rem;
  font-size: 12px;
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
  color: var(--color-contrast-brand);
}

.label-style{
  background: white;
  margin-left: 10px;
}

.label-style-title {
  margin-left: 0px;
}