.container-timeline {
    width: 474px;
    height: auto;
    margin-left: 30px;
    display: flex;
    justify-content: center;
    background-color: white;
}

.body-timeline {
    margin-left: 5px;
    width: 100%;
    height: auto;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: inset 13px 0 var(--color-contrast-white), inset 16px 0 var(--color-brand-200);
}

.line-timeline {
    height: auto;
    width: 3px;
    background-color: var(--color-brand-200);
    opacity: 0.3;
    position: relative;
    left: 16px;
}

.items-timeline {
    width: 100%;
}

.btn-check-cancellation{
  width: 112px;
}
