.left-content
.right-content {
    width: 100%;
}

.left-content .left-list-report {
    width: 100%;
    cursor: pointer;
    min-height: 30px;
    box-shadow: -2px 2px 5px #d0d2d4;
    margin-top: 10px;
    padding: 10px;
    background: transparent;
    border: none;
    text-align: justify;
}

.report-selected {
    box-shadow: -2px 2px 5px var(--color-neutral-600) !important;
}

.left-content .left-list-report span {
    vertical-align: middle;
}

.report-span-text {
    line-height: 1.8rem;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    color: var(--color-contrast-brand);
}

.font-title {
    font-size: 14px;
}

.font-list-report {
    font-size: 12px;
}


.filter-divisor {
    background-color: rgba(197,208,225,0.6);
    height: 1px;
    width: 100%;
    color: rgba(197,208,225,0.6);
    border: none;
}

.footerFilterContainer {
    width: 95%;
    position: absolute;
    bottom: 0px;
    padding: 15px;
}

.footerFilterContainer .footer-dialog-buttons{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: right;
}
  
  .footerFilterContainer .footer-dialog-buttons .button{
    margin-left: 2px;
    width: 150px !important;
}