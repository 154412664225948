.listagem-associacoes__table {
  padding: 2px;
}

.listagem-associacoes__table-header {
  display: block;
  padding: 10px;
  font-size: 1.16em;
  text-align: center;
}

.listagem-associacoes__table-body {
  background-color: var(--color-contrast-white);
  color: #000000;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 1px solid var(--color-neutral-300);
  font-size: calc(0.2vw + 1vh);
}

.listagem-associacoes__table-cols {
  display: inline-block;
}

.listagem-associacoes__table-cols .text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listagem-associacoes__table-rows {
  padding: 20px;
  border-bottom: 1px dotted #cfcfcf;
}

.listagem-associacoes__table-col {
  display: flex;
  align-items: center;
}

.icon-right {
  float: right;
}

.listagem-associacoes__table-badge {
  margin-left: 20px;
}

.hPBVMr {
  display: inline;
}

.modal-content {
  overflow: initial;
}

.listagem-associacoes__table-checkbox {
  flex: 0 0 auto;
  padding-bottom: 0px;
}

.kKPzOa .checkbox-items-list .checkbox-item {
  margin-bottom: 0px;
}

.icone {
  width: 8px;
  height: 8px;
  cursor: pointer
}

.text {
  display: flex;
  align-items: center;
}

button.onLinking,
button.onUnlink {
  margin-right: 8px;
  border: transparent;
  background-color: transparent;
  border-radius: 50%;
  padding: 2px;
}