.card-alert-container{
    width:0;
    height:0;
    border:1em solid;
    font-size: 25px;
    transform: rotate(45deg);
    position: absolute;
    right: -25px;
    top: -26px;
    border-color: transparent transparent #eb1a21 transparent
}

.card-alert-container span{
    object-fit: contain;
    font-family: Catamaran;
    font-size: 18px;
    font-weight: bold;
    line-height: 0.83;
    color: var(--color-contrast-white);
    transform: rotate(-45deg) !important;
    position: absolute;
    top: 8px;
    right: -5px;
}