.scroll-bar-visibility::-webkit-scrollbar {
  height: 4px;
  width: 4px;              
  border: 1px solid #d5d5d5;
}

button.familia-rotulada__one, button.familia-rotulada__two {
  background: transparent;
  border: none;
  text-align: center;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0px;
  margin: 0px;
}