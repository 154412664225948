.nomargin {
  margin: 0px;
  padding: 0px;
}

.buttomdeadline {
  margin-top: 28px !important;
  padding: 0px !important;
}

.nopaddinright {
  padding-right: 0px !important;
  padding-left: 0.5rem;
}

.nopaddinleft {
  padding-left: 0px !important;
  padding-right: 0.5rem;
}

.columnsSize {
  flex-basis: calc(50% - 29px) !important;
  max-width: calc(50% - 29px) !important;
}

.icon {
  width: 30px;
  height: 30px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.date-separator-text {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  color: var(--color-action-default);
  background-color: var(--color-neutral-200) !important;
  border: solid 1px var(--color-action-hover);
}

.date-separator-text h5 {
  margin: 0px;
  text-transform: uppercase;
}
