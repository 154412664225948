.material-business-unit-container {
    height: calc(100vh - 235px);
    position: relative;
    background-color: white ;
}

.material-business-unit-header {
  width: calc(100%);
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  height: 100px;
  align-items: center;
  background-color: var(--color-neutral-background) ;
}

.general-button {
  margin-left: 10px;
}

.button-return {
  margin: 0px 10px;
  width: 150px!important;
}

.filterContainer {
  margin: 0!important;
}

.buttons-header-container {
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
}

.container-action-buttons__material-supplier {
  display:flex;
  width: 40%;
  justify-content: flex-end;
}

.button__material_supplier {
  width: 170px;
  margin: 2px;
}

