.expansion-panel {
  height: 70px;
}

.changelog {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.changelog div {
  display: flex;
  align-items: center;
}

.changelog-first-session > div:not(:first-child) {
  margin-left: 16px;
}


.refresh-icon {
  width: 40px;
  height: 40px;
  display: block;
  margin: auto;
  background-color: var(--color-neutral-200);
  border-radius: 100%;
}

.refresh-icon > svg {
  width: 32px;
  height: 32px;
  padding: 6px;
}

.icon > img {
  width: 32px;
  height: 32px;
  padding: 8px;
}

.version {
  color: var(--color-action-default);
  font-size: 14px;
  font-weight: var(--font-weight-semibold);
  opacity: 1;
}

.separator {
  width: 1px;
  height: 20px;
  background: var(--color-neutral-300) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.deployed-in {
  font-size: 11px;
  font-weight: var(--font-weight-semibold);
  color: var(--color-neutral-500);
  opacity: 1;
  text-transform: uppercase;
}

.details {
  width: 100%;
  display: flex;
  font-size: 12px;
}

.details-first-session {
  width: 30%;
}

.details-second-session {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.details-text {
  color: var(--color-action-default);
  font-size: 12px;
  font-weight: var(--font-weight-semibold);
  opacity: 1;
}

.line {
  width: 100%;
  background-color: var(--color-neutral-500);
  opacity: .3;
  height: 1px;
  display: block;
  margin-top: 15px;
}

.margin {
  margin-bottom: 10px;
}

.pagination {
  float: right;
}

.display-linebreak {
  white-space: pre-line;
}