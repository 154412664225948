.line-2-columns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.requests-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  height: 40px;
  background-color: #f5f5f5;
  border-radius: 3px;
}

.requests-header span:first-child {
  font-size: 18px;
  font-weight: bold;
  flex-basis: 100%;
  text-align: center;
}

.filter-request {
  width: 230px;
  position: absolute;
  right: 25px;
}

.list-pedidos {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 0;
}

.msg-empty-pedidos {
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
}

.msg-empty-pedidos span {
  text-align: center;
  color: red;
  font-weight: bold;
}

.container-action-buttons .button {
  margin: 2px;
  display: inline-block;
  width: 166px !important;
}

.col-date,
.col-select-box,
.col-total,
.col-observation,
.col-buttom {
  margin-left: 5px;
}

.col-date {
  width: 150px;
}

.col-select-box {
  flex: 1;
  min-width: 150px;
}

.col-total {
  width: 130px;
}

.col-observation {
  flex: 1;
}

.rowListHeader {
  color: var(--color-contrast-brand);
  font-weight: var(--font-weight-semibold);
  padding: 5px 0 7px 0;
  border-bottom: 1px dashed var(--color-neutral-300);
  padding-left: 4px;
  text-transform: uppercase;
  justify-content: center;
}

.btn-observation {
  margin-top: 28px;
}

.div-quantidade {
  margin-left: 20px;
  margin-top: 40px;
  margin-bottom: 10px;
  display: inline-block;
  position: absolute;
  left: 0;
}

.lbl-quantidade {
  position: absolute;
  left: 10px;
  line-height: 1.8rem;
  font-size: 12px;
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
  color: var(--color-contrast-brand);
}

.lbl-quantidade-valor {
  font-size: 16px;
  font-weight: bold;
}

.container-action-buttons__composition {
  padding-bottom: 90px !important;
}

.button__composicao-carga {
  margin: 2px;
  width: 170px !important;
  float: right;
  margin-top: 30px;
}

.button__composicao-carga_emergency_negotiation {
  margin: 2px;
  width: auto !important;
  min-width: 170px !important;
  float: right;
  margin-top: 30px;
}

.btn-close-modal {
  font-weight: var(--font-weight-medium);
  font-size: 28px;
  position: absolute;
  top: 14px;
  right: 14px;
}

.warning-waiting-return{
  color: var(--color-feedback-danger-300, #CB3C40);
  text-align: center;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: normal;
  text-transform: uppercase;
  margin-left: 7px;
}