.button {
  margin: 2px;
  width: 170px;
}

.errorContainer {
    font-size: 14px;
    color: red;
}

.errorContainer p {
    margin: 1px;
}

.container {
  width: 97% !important;
  padding: 7px;
  border: 1px solid var(--color-neutral-300) !important;
}

div[class="row"] {
  padding-top: 5px !important;
}