.drp-span-text {
  font-size: 12px;
  line-height: 1.8rem;
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
  color: var(--color-contrast-brand);
}

.drp-nomargin {
  margin: 0px;
  padding: 0px;
}

.drp-buttomdeadline {
  padding: 0px!important;
}

.drp-nopaddinright {
  padding-right: 0px!important;
  padding-left: .5rem;
}

.drp-nopaddinleft {
  padding-left: 0px!important;
  padding-right: .5rem;
}

.drp-columnsSize {
  flex-basis: calc(50% - 29px)!important;
  max-width: calc(50% - 29px)!important;
  min-width: 160px;
}