.container-action-buttons__deleteModal {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.container-action-buttons__deleteModal--buttons {
  width: 30%;
  margin-left: 5px;
}
